import { create } from 'zustand'

interface UserProfileState {
  open: boolean
  setOpen: (open: boolean) => void
}

export const useUserProfileStore = create<UserProfileState>((set, get) => ({
  open: false,
  setOpen: open => set({ open }),
}))
