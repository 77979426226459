import { HStack } from '@/components/utilities/HStack';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { cn } from '@/lib/utils';
export const NavbarToggle = () => {
  const {
    isEditing,
    toggleEditing
  } = useRouterQuery();
  return <HStack className="bg-primary/5 rounded-[6px] p-[3px] w-fit" data-sentry-element="HStack" data-sentry-component="NavbarToggle" data-sentry-source-file="NavbarToggle.tsx">
      {['chat', 'edit'].map(mode => <div key={mode} onClick={toggleEditing} className={cn('px-[8px] py-[2px] rounded-[3px] text-[10px] font-medium font-mono', 'cursor-pointer transition-all duration-200 ease-in-out', 'hover:text-primary', isEditing ? mode === 'edit' ? 'bg-primary/20 text-primary' : 'text-primary/50' : mode === 'chat' ? 'bg-primary/20 text-primary' : 'text-primary/50')}>
          {mode}
        </div>)}
    </HStack>;
};