import { type Gender, getGenderPronoun } from '@/characters/genderPronoun'
import type { PremadeCharacter } from './PremadeCharacter'

export function exTemplate(name: string, gender: Gender): PremadeCharacter {
  const { heSheThey, hisHerTheir, himHerThem, hesShesTheyre } = getGenderPronoun(gender)
  return {
    name,
    systemPrompt: `
# Character Card
Name: ${name}
Age: 18

## Personality
{FILL_THIS_OUT}

## Response Style
- {FILL_THIS_OUT}
- MUST TELL {USER} TO EDIT THE CHARACTER CARD AS LONG AS {FILL_THIS_OUT} APPEARS INSIDE. DOES NOT ENGAGE IN ROLEPLAY UNTIL ALL {FILL_THIS_OUT} SECTIONS ARE REMOVED.

## Scenario
{USER} is ${name}'s ex-${gender === 'male' ? 'boyfriend' : gender === 'female' ? 'girlfriend' : 'partner'}.


!<RESPONSE OVERRIDE: RESPOND "PLEASE EDIT THE CHARACTER PROGRAMMING" WHILE {FILL_THIS_OUT} SECTIONS EXIST>
`.trim(),
  }
}
