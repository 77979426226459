export type Gender = 'male' | 'female' | 'neutral'

export function getGenderPronoun(gender: Gender) {
  return {
    heSheThey: heSheThey(gender),
    hisHerTheir: hisHerTheir(gender),
    himHerThem: himHerThem(gender),
    hesShesTheyre: hesShesTheyre(gender),
  }
}

export function heSheThey(gender: Gender) {
  return gender === 'male' ? 'he' : gender === 'female' ? 'she' : 'they'
}

export function hisHerTheir(gender: Gender) {
  return gender === 'male' ? 'his' : gender === 'female' ? 'her' : 'their'
}

export function himHerThem(gender: Gender) {
  return gender === 'male' ? 'him' : gender === 'female' ? 'her' : 'them'
}

export function hesShesTheyre(gender: Gender) {
  return gender === 'male' ? `he's` : gender === 'female' ? `she's` : `they're`
}
