import { cn } from '@/lib/utils';
import { type HTMLAttributes, type PropsWithChildren, forwardRef } from 'react';
interface VStackProps extends HTMLAttributes<HTMLDivElement> {
  alignment?: 'start' | 'center' | 'end' | 'baseline';
}

/**
 * A vertical stack of components, using flexbox.
 */
export const VStack = forwardRef<HTMLDivElement, PropsWithChildren<VStackProps>>((props, ref) => {
  const {
    className,
    alignment,
    children,
    ...rest
  } = props;
  return <div ref={ref} className={cn('flex h-full flex-col', `items-${alignment ?? 'center'}`, className)} {...rest}>
      {children}
    </div>;
});
VStack.displayName = 'VStack';