import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import type { AvatarProps } from '@radix-ui/react-avatar';
export interface CustomAvatarProps extends AvatarProps {
  display: string;
  avatar?: string | null | undefined;
}
export function CustomAvatar(props: CustomAvatarProps) {
  const base = `https://fadeaway-avatar${process.env.NODE_ENV === 'development' ? '-dev' : ''}.s3.us-west-2.amazonaws.com/`;
  const url = props.avatar ? `${base}${props.avatar}` : undefined;
  function getInitials(name: string) {
    const parts = name.split(' ');
    if (parts.length > 1) {
      const [first, last] = parts;
      return `${first[0]}${last[0]}`.toUpperCase().trim();
    } else {
      return name.slice(0, 1).toUpperCase().trim();
    }
  }
  return <Avatar {...props} data-sentry-element="Avatar" data-sentry-component="CustomAvatar" data-sentry-source-file="CustomAvatar.tsx">
      {url ? <AvatarImage alt="Picture" src={url} /> : <AvatarFallback className="rounded-none text-primary/60">
          <span>{getInitials(props.display)}</span>
          {/* <Icons.user className="h-4 w-4" /> */}
        </AvatarFallback>}
    </Avatar>;
}