import type { Gender } from '@/characters/genderPronoun'
import type { PremadeCharacter } from './PremadeCharacter'

export function blankTemplate(name: string, gender: Gender): PremadeCharacter {
  return {
    name,
    systemPrompt: `
# Character Card
Name: ${name}

## Personality
A blank slate.
`.trim(),
  }
}
