import { useConversationStore } from '@/components/store/ConversationStore';
import { useUserProfileStore } from '@/components/store/UserProfileStore';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useEffect, useState } from 'react';
export function UserProfileDialog() {
  const open = useUserProfileStore(state => state.open);
  const setOpen = useUserProfileStore(state => state.setOpen);
  const updateMe = useConversationStore(state => state.updateMe);
  const [name, setName] = useState('');
  useEffect(() => {
    if (open) {
      setName(useConversationStore.getState().me?.name ?? '');
    }
  }, [open]);
  const handleSave = () => {
    updateMe({
      name
    });
    setOpen(false);
  };
  return <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-component="UserProfileDialog" data-sentry-source-file="UserProfileDialog.tsx">
      <DialogContent className="sm:max-w-[425px]" data-sentry-element="DialogContent" data-sentry-source-file="UserProfileDialog.tsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="UserProfileDialog.tsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="UserProfileDialog.tsx">Your profile</DialogTitle>
          <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="UserProfileDialog.tsx">{`Make changes to your profile here. Click save when you're done.`}</DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right" data-sentry-element="Label" data-sentry-source-file="UserProfileDialog.tsx">
              Name
            </Label>
            <Input id="name" className="col-span-3" value={name} onChange={e => setName(e.target.value)} onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSave();
            }
          }} data-sentry-element="Input" data-sentry-source-file="UserProfileDialog.tsx" />
          </div>
        </div>
        <DialogFooter data-sentry-element="DialogFooter" data-sentry-source-file="UserProfileDialog.tsx">
          <Button type="submit" onClick={handleSave} data-sentry-element="Button" data-sentry-source-file="UserProfileDialog.tsx">
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>;
}