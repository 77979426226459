import { useRouter } from 'next/router'
import { useCallback } from 'react'

export function useRouterQuery() {
  const router = useRouter()
  const { query } = router

  const characterId = query.characterId as string | null
  const isEditing = query.edit === 'true'

  const toggleEditing = useCallback(() => {
    if (router.pathname === '/_error') {
      return
    }

    const newQuery = { ...query }
    if (isEditing) {
      delete newQuery.edit
      delete newQuery.tab
    } else {
      newQuery.edit = 'true'
    }

    router.push(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true }
    )
  }, [query, isEditing, router.pathname])

  return {
    characterId,
    isEditing,
    toggleEditing,
  }
}
