import { HStack } from '@/components/utilities/HStack';
import { VStack } from '@/components/utilities/VStack';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { cn } from '@/lib/utils';
import Head from 'next/head';
import Link from 'next/link';
interface LayoutViewProps {
  className?: string;
  children?: React.ReactNode;
}
export const LayoutView = (props: LayoutViewProps) => {
  const {
    children,
    className
  } = props;
  const {
    isEditing
  } = useRouterQuery();
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="LayoutView.tsx">
        <title>fadeaway</title>
      </Head>
      <VStack className={cn('h-screen w-screen overflow-hidden', 'transition-colors duration-200 ease-in-out relative', isEditing ? 'bg-[#1e1e1e]' : 'bg-[#282A36]')} data-sentry-element="VStack" data-sentry-source-file="LayoutView.tsx">
        <main className="flex h-full w-full flex-col overflow-hidden">{children}</main>
        <HStack className="absolute bottom-0 left-0 right-0 w-full justify-end space-x-4 px-[12px] py-[4px] text-[8px] font-mono" data-sentry-element="HStack" data-sentry-source-file="LayoutView.tsx">
          <Link href="/terms" className="hover:underline opacity-40 hover:opacity-100 transition-opacity duration-200 ease-in-out" data-sentry-element="Link" data-sentry-source-file="LayoutView.tsx">
            terms
          </Link>
          <Link href="/privacy" className="hover:underline opacity-40 hover:opacity-100 transition-opacity duration-200 ease-in-out" data-sentry-element="Link" data-sentry-source-file="LayoutView.tsx">
            privacy
          </Link>
        </HStack>
      </VStack>
    </>;
};