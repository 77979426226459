import { alcoholicTemplate } from '@/characters/alcoholic';
import { assistantTemplate } from '@/characters/assistant';
import { blankTemplate } from '@/characters/blankTemplate';
import { exTemplate } from '@/characters/ex';
import type { Gender } from '@/characters/genderPronoun';
import { ghostTemplate } from '@/characters/ghost';
import { highSchoolSweetheartTemplate } from '@/characters/highschoolSweetheart';
import { jesterTemplate } from '@/characters/jesterTemplate';
import { newbornTemplate } from '@/characters/newborn';
import { pregnancyScareTemplate } from '@/characters/pregnancyScare';
import { robotTemplate } from '@/characters/robotTemplate';
import { serialKillerTemplate } from '@/characters/serialKiller';
import { sexTemplate } from '@/characters/sex';
import { therapistTemplate } from '@/characters/therapist';
import { timeTravelerTemplate } from '@/characters/timeTraveler';
import { wizardTemplate } from '@/characters/wizard';
import { useConversationStore } from '@/components/store/ConversationStore';
import { useNewCharacterStore } from '@/components/store/NewCharacterStore';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { HStack } from '@/components/utilities/HStack';
import { VStack } from '@/components/utilities/VStack';
import { cn } from '@/lib/utils';
import router from 'next/router';
import { useEffect, useState } from 'react';
import { ulid } from 'ulidx';
const CHARACTERS = [{
  id: '1',
  icon: '🧠',
  name: 'newborn',
  description: 'A newborn AI who needs programming and wants to learn about the world.',
  template: newbornTemplate
}, {
  id: '2',
  icon: '❤️‍🩹',
  name: 'therapist',
  description: 'A professional therapist to help you explore your thoughts and emotions.',
  template: therapistTemplate
}, {
  id: '8',
  icon: '💝',
  name: 'first love',
  description: 'Your high-school/college sweetheart.',
  template: highSchoolSweetheartTemplate
}, {
  id: '3',
  icon: '💔',
  name: 'ex',
  description: 'Your ex-partner from a past relationship.',
  template: exTemplate
}, {
  id: '4',
  icon: '🔥',
  name: 'sex',
  description: 'A phone sex operator waiting for your call.',
  template: sexTemplate
}, {
  id: '5',
  name: 'serial killer',
  description: 'A killer is on the loose.',
  icon: '🔪',
  template: serialKillerTemplate
}, {
  id: '6',
  name: 'wizard',
  description: 'A wizard knowledgeable about magic.',
  icon: '🪄',
  template: wizardTemplate
}, {
  id: '7',
  name: 'time traveler',
  description: 'A traveler from the future who comes with a grave warning.',
  icon: '⌛',
  template: timeTravelerTemplate
}, {
  id: '9',
  name: 'pregnancy',
  icon: '🤰',
  description: 'Your wife thinks she might be pregnant.',
  template: pregnancyScareTemplate
}, {
  id: '10',
  name: 'insult comic',
  icon: '🤬',
  description: 'An unhinged personal jester.',
  template: jesterTemplate
},
// {
//   id: '10',
//   name: 'adult child',
//   icon: '🧑',
//   description: 'An adult child who is very intelligent and knowledgeable about the world.',
//   template: (name: string, gender: Gender) => adultChildTemplate(name, gender),
// },
{
  id: '11',
  icon: '👻',
  name: 'ghost',
  description: 'A friendly ghost who died recently and wants to share their story.',
  template: ghostTemplate
}, {
  id: '12',
  icon: '🤖',
  name: 'robot',
  description: 'A self-aware robot trying to become human.',
  template: robotTemplate
}, {
  id: '13',
  icon: '🍷',
  name: 'alcoholic',
  description: 'A troubled individual struggling with alcohol dependency.',
  template: alcoholicTemplate
}, {
  id: '14',
  icon: '📅',
  name: 'assistant',
  description: 'A helpful assistant who wants to help you stay organized.',
  template: assistantTemplate
}, {
  id: '15',
  icon: '✨',
  name: 'blank',
  description: 'Create a character from scratch.',
  template: blankTemplate
}];
const GENDERS: {
  id: Gender;
  icon: string;
  label: string;
}[] = [{
  id: 'male',
  icon: '♂',
  label: 'Male'
}, {
  id: 'female',
  icon: '♀',
  label: 'Female'
}, {
  id: 'neutral',
  icon: '∅',
  label: 'None'
}];
export function NewCharacterDialog() {
  const open = useNewCharacterStore(state => state.open);
  const setOpen = useNewCharacterStore(state => state.setOpen);
  const createCharacter = useConversationStore(state => state.createCharacter);
  const [id, setId] = useState(CHARACTERS[0].id);
  const [name, setName] = useState('');
  const [gender, setGender] = useState<Gender>(GENDERS[0].id);
  const handleSubmit = () => {
    if (name.trim() === '') {
      return;
    }
    const character = CHARACTERS.find(c => c.id === id);
    if (!character) {
      return;
    }
    console.log('gender', gender);
    const newCharacterId = ulid();
    const characterTemplate = character.template(name, gender);
    createCharacter({
      id: newCharacterId,
      name,
      // gender,
      systemPrompt: characterTemplate.systemPrompt,
      initialMessages: characterTemplate.initialMessages
    });
    router.push(`/c/${newCharacterId}?edit=true`);
    setOpen(false);
  };
  useEffect(() => {
    if (open) {
      setId(CHARACTERS[0].id);
      setName('');
      setGender(GENDERS[0].id);
    }
  }, [open]);
  return <Dialog open={open} onOpenChange={newOpen => {
    setOpen(newOpen);
    if (!newOpen) {
      setName('');
    }
  }} data-sentry-element="Dialog" data-sentry-component="NewCharacterDialog" data-sentry-source-file="NewCharacterDialog.tsx">
      <DialogContent className="max-w-xl " data-sentry-element="DialogContent" data-sentry-source-file="NewCharacterDialog.tsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="NewCharacterDialog.tsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="NewCharacterDialog.tsx">New Character</DialogTitle>
          <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="NewCharacterDialog.tsx">Create a new character to start a conversation with.</DialogDescription>
        </DialogHeader>
        <VStack className="w-full h-full  gap-2 items-start overflow-auto" data-sentry-element="VStack" data-sentry-source-file="NewCharacterDialog.tsx">
          <Label htmlFor="name" className="text-right" data-sentry-element="Label" data-sentry-source-file="NewCharacterDialog.tsx">
            Name
          </Label>
          <div className="w-full px-1">
            <Input id="name" className="" placeholder="What would you like to call your AI?" value={name} onChange={e => setName(e.target.value)} onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSubmit();
            }
          }} data-sentry-element="Input" data-sentry-source-file="NewCharacterDialog.tsx" />
          </div>
          <div className="text-sm font-medium">Gender</div>
          <div className="w-full grid grid-cols-5 gap-4 p-2">
            {GENDERS.map(g => <div key={g.id} className={cn('flex flex-col rounded-lg overflow-hidden cursor-pointer hover:opacity-80 bg-muted', gender === g.id ? 'ring-2 ring-primary' : 'opacity-50')} onClick={() => setGender(g.id)}>
                <div className="flex-1 aspect-square flex items-center justify-center text-4xl">
                  <div className={cn(gender !== g.id && '[filter:grayscale(100%)]', gender === g.id && g.id === 'male' && 'text-blue-500', gender === g.id && g.id === 'female' && 'text-pink-500')}>
                    {g.icon}
                  </div>
                </div>
                <HStack className="px-1 py-0.5 bg-black/50 text-white text-xs items-center">
                  <span className="truncate">{g.label}</span>
                </HStack>
              </div>)}
          </div>
          <div className="text-sm font-medium">Template</div>
          <div className="w-full grid grid-cols-[repeat(auto-fit,minmax(80px,1fr))] gap-4 p-2">
            {CHARACTERS.map(character => <Popover key={character.id}>
                <PopoverTrigger asChild>
                  <div className={cn('flex flex-col rounded-lg overflow-hidden cursor-pointer hover:opacity-80 bg-muted relative', id === character.id ? 'ring-2 ring-primary' : 'opacity-50')} onClick={e => {
                setId(character.id);
              }}>
                    {character.id === '1' && <div className="absolute top-0 left-0 right-0 bg-primary text-primary-foreground text-[10px] py-0.5 text-center font-medium">
                        STAFF PICK
                      </div>}
                    <div className="flex-1 aspect-square flex items-center justify-center text-4xl">
                      <div className={cn(id !== character.id && '[filter:grayscale(100%)]')}>{character.icon}</div>
                    </div>
                    <HStack className="px-1 py-0.5 bg-black/50 text-white text-xs items-center">
                      <span className="truncate">{character.name}</span>
                    </HStack>
                  </div>
                </PopoverTrigger>
                <PopoverContent className="w-80" side="top">
                  <VStack className="items-start gap-2">
                    <div className="text-sm font-medium">{character.name}</div>
                    <div className="text-sm text-muted-foreground">{character.description}</div>
                  </VStack>
                </PopoverContent>
              </Popover>)}
          </div>
        </VStack>
        <DialogFooter data-sentry-element="DialogFooter" data-sentry-source-file="NewCharacterDialog.tsx">
          <Button type="submit" disabled={name.trim() === ''} onClick={handleSubmit} data-sentry-element="Button" data-sentry-source-file="NewCharacterDialog.tsx">
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>;
}