import { useConversationStore, type Char } from '@/components/store/ConversationStore';
import { useNewCharacterStore } from '@/components/store/NewCharacterStore';
import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { CustomAvatar } from '@/components/utilities/CustomAvatar';
import { HStack } from '@/components/utilities/HStack';
import { VStack } from '@/components/utilities/VStack';
import { useRouterQuery } from '@/hooks/useRouterQuery';
import { cn } from '@/lib/utils';
import { IconCheck, IconChevronDown, IconPlus } from '@tabler/icons-react';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
interface CharacterPopoverProps {
  character: Char;
}
export const CharacterPopover = (props: CharacterPopoverProps) => {
  const {
    character
  } = props;
  const router = useRouter();
  const {
    isEditing
  } = useRouterQuery();
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const convoStore = useConversationStore();
  // const { data, previousData } = useSearchCharacters({
  //   variables: {
  //     input: {
  //       query,
  //       limit: 8,
  //     },
  //   },
  // })

  // const characters = [
  //   ...(data?.searchCharacters ?? previousData?.searchCharacters ?? []).filter(c => c.id !== character.id),
  //   ...(query === '' ? [character] : []),
  // ]
  const characters = Object.values(convoStore.characters).slice(0, 7).sort((a, b) => a.name.localeCompare(b.name));
  return <Popover open={open} onOpenChange={setOpen} data-sentry-element="Popover" data-sentry-component="CharacterPopover" data-sentry-source-file="CharacterPopover.tsx">
      <PopoverTrigger data-sentry-element="PopoverTrigger" data-sentry-source-file="CharacterPopover.tsx">
        <HStack className={cn('gap-0.5 opacity-80 hover:opacity-100 shrink-0')} data-sentry-element="HStack" data-sentry-source-file="CharacterPopover.tsx">
          {/* <CustomAvatar
            className="w-[20px] h-[20px] text-[10px] font-bold"
            avatar={character.avatarId}
            display={character.name}
           /> */}
          <div className="font-semibold truncate max-w-[128px]">{character.name}</div>
          <IconChevronDown className="" size={8} data-sentry-element="IconChevronDown" data-sentry-source-file="CharacterPopover.tsx" />
        </HStack>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" data-sentry-element="PopoverContent" data-sentry-source-file="CharacterPopover.tsx">
        <Command shouldFilter={false} data-sentry-element="Command" data-sentry-source-file="CharacterPopover.tsx">
          <CommandInput className="h-9 text-xs" placeholder="Search character..." value={query} onValueChange={value => setQuery(value)} data-sentry-element="CommandInput" data-sentry-source-file="CharacterPopover.tsx" />
          <CommandList data-sentry-element="CommandList" data-sentry-source-file="CharacterPopover.tsx">
            <CommandEmpty className="text-xs" data-sentry-element="CommandEmpty" data-sentry-source-file="CharacterPopover.tsx">
              <VStack className="px-2 py-4 items-start gap-2" data-sentry-element="VStack" data-sentry-source-file="CharacterPopover.tsx">
                <div className="text-xs">No character found.</div>
                <div className="text-xs text-muted-foreground">
                  Your search for &quot;{query}&quot; did not match any characters.
                </div>
                <Button variant="outline" size="sm" className="w-full" onClick={() => setQuery('')} data-sentry-element="Button" data-sentry-source-file="CharacterPopover.tsx">
                  Clear Search
                </Button>
              </VStack>
            </CommandEmpty>
            <CommandGroup data-sentry-element="CommandGroup" data-sentry-source-file="CharacterPopover.tsx">
              {characters.map(c => <CommandItem className="text-xs" key={c.id} value={c.id} onSelect={currentValue => {
              setQuery('');
              setOpen(false);
              if (isEditing) {
                router.push(`/c/${c.id}?edit=true`);
              } else {
                router.push(`/c/${c.id}`);
              }
            }}>
                  <CustomAvatar className="w-[20px] h-[20px] text-[10px] font-bold" avatar={c.avatarId} display={c.name} />
                  {c.name}
                  <IconCheck className={cn('ml-auto', c.id === character.id ? 'opacity-100' : 'opacity-0')} />
                </CommandItem>)}
              {query === '' && <CommandItem className="text-xs" value="new" onSelect={currentValue => {
              useNewCharacterStore.getState().setOpen(true);
            }}>
                  <HStack className="w-[20px] h-[20px] bg-primary/10 rounded-full justify-center items-center text-[12px] text-primary/50">
                    <IconPlus size={6} />
                  </HStack>
                  Create Character
                </CommandItem>}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>;
};