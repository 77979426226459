import { create } from 'zustand'

interface NewCharacterState {
  open: boolean
  setOpen: (open: boolean) => void
}

export const useNewCharacterStore = create<NewCharacterState>((set, get) => ({
  open: false,
  setOpen: open => set({ open }),
}))
