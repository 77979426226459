import { UserProfileDialog } from '@/components/navbar/UserProfileDialog';
import { useConversationStore } from '@/components/store/ConversationStore';
import { useUserProfileStore } from '@/components/store/UserProfileStore';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { HStack } from '@/components/utilities/HStack';
import { IconChevronDown } from '@tabler/icons-react';
export const UserDropdown = () => {
  // const user = useContext(UserContext)
  const name = useConversationStore(state => state.me?.name);
  return <>
      <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-source-file="UserDropdown.tsx">
        <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="UserDropdown.tsx">
          {/* <Button variant="outline">Open</Button> */}
          <HStack className="items-center justify-center w-fit gap-[4px] opacity-80 hover:opacity-100 cursor-pointer p-[2px]" data-sentry-element="HStack" data-sentry-source-file="UserDropdown.tsx">
            <div className="text-[#f9f9f9] text-[10px] font-medium font-mono tracking-wide">{name}</div>
            <IconChevronDown className="w-[10px] h-[10px]" data-sentry-element="IconChevronDown" data-sentry-source-file="UserDropdown.tsx" />
          </HStack>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" data-sentry-element="DropdownMenuContent" data-sentry-source-file="UserDropdown.tsx">
          <DropdownMenuGroup data-sentry-element="DropdownMenuGroup" data-sentry-source-file="UserDropdown.tsx">
            <DropdownMenuItem onSelect={() => {
            useUserProfileStore.getState().setOpen(true);
          }} data-sentry-element="DropdownMenuItem" data-sentry-source-file="UserDropdown.tsx">
              {/* <IconUser /> */}
              <span>Edit name</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="UserDropdown.tsx" />
          <DropdownMenuItem className="text-red-500/80" onSelect={() => {
          localStorage.clear();
          window.location.href = '/';
        }} data-sentry-element="DropdownMenuItem" data-sentry-source-file="UserDropdown.tsx">
            {/* <IconTrash /> */}
            <span>Delete all data</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <UserProfileDialog data-sentry-element="UserProfileDialog" data-sentry-source-file="UserDropdown.tsx" />
    </>;
};