import { cn } from '@/lib/utils';
import { type HTMLAttributes, type PropsWithChildren, forwardRef } from 'react';
interface HStackProps extends HTMLAttributes<HTMLDivElement> {
  alignment?: 'start' | 'center' | 'end' | 'baseline';
}

/**
 * A horizontal stack of components, using flexbox.
 */
export const HStack = forwardRef<HTMLDivElement, PropsWithChildren<HStackProps>>((props, ref) => {
  const {
    className,
    alignment,
    children,
    ...rest
  } = props;
  return <div ref={ref} className={cn('flex w-full flex-row', `items-${alignment ?? 'center'}`, className)} {...rest}>
      {children}
    </div>;
});
HStack.displayName = 'HStack';